import React, { useState } from "react";
import "./index.scss";
import closeModal from "./x.png";
import button from "./button-go.png";
import ReactPlayer from "react-player";

function App() {
  const [isOpenFirst, setIsOpenFirst] = useState(false);
  const [isOpenSecond, setIsOpenSecond] = useState(false);
  const [isLastScreen, setIsLastScreen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const setOpenVideo = () => {
    setIsOpenFirst(true);
    setOpenModal(true);
  };
  const setCloseVideo = () => {
    setIsOpenSecond(false);
    setIsLastScreen(true);
    setOpenModal(false);
    setIsOpenSecond(true);
  };

  const reloadPage = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <>
      {openModal && (
        <div className={`modal-content-exit`}>
          <div className="close-modal-exit">
            <img src={closeModal} onClick={() => setCloseVideo()} />
          </div>
          <div className="multiple-videos-exit">
            <div className="second">
              {isOpenSecond && (
                <>
                  <ReactPlayer
                    isOpen={isOpenSecond}
                    playing={false}
                    config={{
                      youtube: {
                        playerVars: { showinfo: 1, controls: 1 },
                      },
                    }}
                    url="https://www.youtube.com/watch?v=tqRW0m52nEE"
                  />
                  <ReactPlayer
                    isOpen={isOpenSecond}
                    playing={false}
                    config={{
                      youtube: {
                        playerVars: { showinfo: 1, controls: 1 },
                      },
                    }}
                    url="https://www.youtube.com/watch?v=NyRn4cF3fzI"
                  />
                </>
              )}
            </div>

            <div className="first">
              {isOpenFirst && (
                <ReactPlayer
                  isOpen={isOpenFirst}
                  playing={isOpenFirst}
                  onEnded={() => {
                    setIsOpenFirst(false);
                    setIsOpenSecond(true);
                  }}
                  config={{
                    youtube: {
                      playerVars: { showinfo: 1, controls: 1 },
                    },
                  }}
                  url="http://www.youtube.com/embed/T4Z3GzGPOkg"
                />
              )}
            </div>
          </div>
        </div>
      )}
      <div className="button-exit-exit">
        <button onClick={() => setOpenVideo()}>Sair</button>
      </div>
      {isLastScreen && (
        <div className="last-screen">
          <div className="container-last">
            <div className="color-green">
              <p className="weight-light">
                A SAUR agradece a sua participação no{" "}
              </p>
              <p className="weight-bold">Workshop Técnico 360 GRAUS.</p>
            </div>
            <div className="acess-site">
              <p>Saiba mais sobre nossos produtos:</p>
              <a href="https://www.saur.com.br/" target="_blank">
                <button>Acesse o site</button>
              </a>
            </div>
          </div>
          <div className="back-site">
            <button onClick={() => reloadPage()}>
              <img className="button-seta" src={button} alt="" /> <p>VOLTAR</p>
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
